import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-spinner"

import AjouterPanier from "../components/ajouterPanier"
import { get_accessoire_to_add } from "../services/panier"

import Img from "gatsby-image"
import SEO from "./../components/seo"
import Layout from "../components/layout"
import { useSelector } from "react-redux"

export const AccessoiresProductPage = props => {
  const identifiants = useSelector(s => s.identifiants).identifiants

  const accessoire = {
    ...get_accessoire_to_add(props.pageContext.product, props.pageContext.product.pv_ht, 1),
    mysqlImages: [props.data.allMysqlAccessoires.edges[0].node.mysqlImages[0]],
  }

  const styleImageDetail = {
    width: 300,
    height: 300,
    position: "relative",
  }

  let d = new Date()

  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO
          title={accessoire.pa_libelle}
          description={accessoire.pa_description}
          jsonLd={[
            {
              "@context": "http://schema.org",
              "@type": "Product",
              sku: accessoire.pa_reference,
              mpn: "A-" + accessoire.pa_reference,
              identifier: accessoire.pa_reference,
              description: accessoire.pa_description,
              name: accessoire.pa_libelle,
              image: process.env.GATSBY_URL_ACTUEL + props.data.allMysqlAccessoires.edges[0].node.mysqlImages[0].childImageSharp.fluid.src,
              url: typeof window !== "undefined" ? window.location.href : "",
              brand: {
                "@type": "Brand",
                name: "France-Cadre",
              },
              offers: {
                "@type": "Offer",
                availability: "http://schema.org/InStock",
                price: accessoire.prix_produit,
                url: typeof window !== "undefined" ? window.location.href : "",
                priceCurrency: "EUR",
                priceValidUntil: d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2),
              },
              itemCondition: "https://schema.org/NewCondition",
            },
          ]}
        />

        <div className="columns is-multiline" style={{ marginTop: "6rem", marginBottom: "0.5rem" }}>
          <div className="column is-12 ">
            <h2>
              <strong>{accessoire.pa_libelle}</strong>
            </h2>
          </div>

          <div className="column is-4 ">
            <div className="cadreFamille" style={{ width: "75%", margin: "0 auto" }}>
              <Img fluid={props.data.allMysqlAccessoires.edges[0].node.mysqlImages[0].childImageSharp.fluid} />
            </div>
          </div>

          <div className="columns is-multiline column is-8 ">
            <div className="column is-full">
              <p>
                Réf. <strong>{accessoire.pa_reference}</strong>
              </p>
              <p style={{ marginTop: "1.5rem", textAlign: "justify" }}>{accessoire.pa_description}</p>
            </div>
            <div className="column is-half">
              {accessoire.pa_image_detail === "" ? "" : <img style={styleImageDetail} src={accessoire.pa_image_accessoires_detail} alt="details" />}
            </div>

            <div style={{ fontSize: "1.1rem", textAlign: "right" }} className={identifiants === null ? "is-hidden" : "column is-half"}>
              <p className="has-text-right">
                Prix Produit (HT) :
                <strong>
                  {" "}
                  {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                    parseFloat(props.pageContext.product.pv_ht * (identifiants !== null && identifiants.mode ? 1 + identifiants.margin / 100 : 1))
                  )}
                </strong>
              </p>
              <AjouterPanier product_selected={accessoire} className="show" hauteur={0} largeur={0} />
            </div>

            <div className={identifiants === null ? "column is-half" : "is-hidden"} style={{ fontSize: "1.1rem", textAlign: "right" }}>
              <Link
                to="/acces"
                className="has-text-right"
                style={{
                  position: "absolute",
                  right: "2rem",
                }}
              >
                <div className="button is-primary has-text-right">Demandez l&apos;accès au site pour obtenir les prix</div>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query accessoiresProductsQuery($pa_id: Int!) {
    allMysqlAccessoires(filter: { pa_id: { eq: $pa_id } }) {
      edges {
        node {
          mysqlImages {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
AccessoiresProductPage.propTypes = {
  id_passe: PropTypes.string,
  mesAccessoires: PropTypes.object,
  pageContext: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object,
}

export default AccessoiresProductPage
